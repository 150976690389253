export const faqs = [
  {
    question: 'What is Invie?',
    answer:
      'Invie is a Simple Inventory Management Software designed to help businesses efficiently track and manage their inventory.',
  },
  {
    question: 'What features does Invie offer?',
    answer:
      'Invie offers features such as inventory tracking, stock alerts, barcode scanning, reporting and analytics, user access control, and integration with other business software.',
  },
  {
    question: 'Is Invie suitable for small businesses?',
    answer:
      'Yes, Invie is suitable for small businesses as well as medium-sized and large enterprises. Its user-friendly interface and customizable features make it adaptable to various business needs.',
  },
  {
    question: 'How can Invie help me manage my inventory?',
    answer:
      'Invie streamlines inventory management by providing tools for accurate tracking of stock levels, easy reorder management, real-time insights into inventory movements, and customizable alerts for low stock levels.',
  },
  {
    question: 'Does Invie support barcode scanning?',
    answer:
      'Yes, Invie supports barcode scanning, allowing you to quickly and accurately record inventory movements and conduct audits with ease.',
  },
  {
    question: 'Can I generate reports with Invie?',
    answer:
      'Yes, Invie provides reporting and analytics features that enable you to generate various types of reports, such as sales reports, inventory valuation reports, and stock movement analysis, to make informed business decisions.',
  },
  {
    question: 'Is Invie compatible with other business software?',
    answer:
      'Yes, Invie offers integrations with popular business software such as accounting systems, e-commerce platforms, and point-of-sale systems, allowing for seamless data exchange and workflow automation.',
  },
  {
    question: 'How secure is my data with Invie?',
    answer:
      'Invie prioritizes data security and employs industry-standard encryption protocols to safeguard your data. Additionally, user access controls ensure that only authorized personnel have access to sensitive information.',
  },
  {
    question: 'Is there customer support available for Invie?',
    answer:
      'Yes, Invie provides customer support to assist users with any inquiries or issues they may encounter. Our support team is available via email, phone, or online chat during business hours.',
  },
  {
    question: 'Can I try Invie before purchasing?',
    answer:
      'Yes, Invie offers a free trial period for interested users to explore its features and functionalities before making a purchase decision. Sign up for a free trial on our website to get started.',
  },
];
