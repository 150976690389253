export const glossary = [
  {
    term: '80-20 Inventory Rule',
    definition:
      'The 80/20 inventory rule states that 80% of your profits should come from 20% of your inventory. The rule is based on the Pareto Principle, a management consulting principle that suggests that 80% of effects come from 20% of causes.',
  },
  {
    term: 'Asset Control',
    definition:
      'Asset control, also called asset tracking, refers to the asset management process companies use to organize, track, audit, and record essential details about long-term assets. A critical part of inventory management, asset control requires a company to adopt an effective asset management system, such as asset management software.',
  },
  {
    term: 'Asset Management Software',
    definition:
      'Asset management software is a software system that stores data about your inventory, including quantity, location, value, and condition. Also known as asset tracking software, it can help businesses account for their property, streamline operations, identify inventory loss, and centralize all information about their assets.',
  },
  {
    term: 'Asset Tagging',
    definition:
      'Asset tagging is an inventory management strategy that requires affixing labels or “tags” to assets. Each tag identifies an individual asset and may display additional information about the item as well.',
  },
  {
    term: 'Asset Tracking',
    definition:
      'Asset tracking is the process of continually managing the assets your business owns, such as equipment, tools, and technology. Asset tracking helps you keep tabs on these assets as they change hands and locations, and as they depreciate over time.',
  },
  {
    term: 'Bill of Materials (BOM)',
    definition:
      'A BOM, or a “bill of materials,” is an itemized list of every single raw material, part, and component needed to manufacture a product. A BOM also lists how much of each item is required and even includes secondary requirements like manuals, guides, and packaging.',
  },
  {
    term: 'Consignment Inventory',
    definition:
      'Consignment inventory, or “consigned inventory,” is a supply chain strategy in which a retailer sells a supplier’s inventory to its customers, but that inventory remains “owned” by the supplier until the product is sold.',
  },
  {
    term: 'Economic Order Quantity',
    definition:
      'Economic order quantity, or EOQ, reveals precisely how much of a product a company should order to meet customer demand while minimizing holding and ordering costs. Economic order quantity (EOQ) helps your business practice more effective inventory control.',
  },
  {
    term: 'Hedging Inventory',
    definition:
      'Hedging inventory—or hedge inventory—is inventory that a business has purchased in anticipation of a significant, uncontrollable event that will likely make the inventory a business needs too challenging to acquire or too expensive to buy.',
  },
  {
    term: 'Inventory',
    definition:
      'Inventory refers to the goods, materials, and assets that a business carries for day-to-day operations.',
  },
  {
    term: 'Inventory Asset',
    definition:
      'An inventory asset is an item your business owns and uses on a continual basis, such as equipment, tools, machinery, vehicles, and more. Inventory assets are not sold to customers nor are they consumed by employees; they are the reusable items that your company uses to create its product or services.',
  },
  {
    term: 'Inventory Automation',
    definition:
      'Inventory management automation is a way to track, analyze, and control items using inventory management software and related technology, including barcodes or QR codes. By automating and digitizing your inventory processes, you can avoid tracking inventory manually or on a spreadsheet which frees up valuable time and reduces the risk of costly errors.',
  },
  {
    term: 'Inventory Cycle Counting',
    definition:
      'An inventory cycle count is an inventory strategy in which specific, defined portions of inventory are counted on a rotating, recurrent schedule. The schedule specifies exactly when each portion of inventory will be counted. This method spreads the count of inventory throughout the year (or another length of time), so you don’t have to to do it all at once.',
  },
  {
    term: 'Inventory List',
    definition:
      'An inventory list is a complete, itemized list of every product your business has in stock. This includes your raw materials, work-in-progress, and finished goods.',
  },
  {
    term: 'Inventory Management',
    definition:
      'Inventory management is the process of ordering, organizing, storing, and utilizing your business’s inventory. Whether your business stocks raw materials or finished goods, inventory management is a key component of keeping your company running smoothly.',
  },
  {
    term: 'Inventory Optimization',
    definition:
      'Inventory optimization occurs when a business maintains a “just right” amount of inventory to satisfy customer demand. By optimizing inventory, a business reduces costs while avoiding the most common inventory-related frustrations, including stockouts, excess inventory, and inventory obsolescence.',
  },
  {
    term: 'Inventory Turnover',
    definition:
      'Inventory turnover refers to how many times a company has sold and replaced inventory over a specific time period, typically a year. Inventory turnover is important because it reveals whether your business stocks excessive inventory, relative to what your company actually uses or sells.',
  },
  {
    term: 'Just in Time Inventory',
    definition:
      'The JIT inventory system is about having the lowest inventory amounts possible in order to minimize inventory costs, increase efficiency and reduce waste.',
  },
  {
    term: 'Kitting',
    definition:
      'Kitting is an inventory management strategy in which individual pieces, parts, or items are bundled into a kit. This “kit” is tracked, used, and even sold as a single piece of inventory, even though it has several (or many) parts.',
  },
  {
    term: 'Landed Cost',
    definition:
      'Also known as true cost, landed cost is the sum of all the different taxes and fees associated with shipping a product plus the cost of creating the product itself. Landed cost includes the cost of customs, risk, and overhead, too.',
  },
  {
    term: 'Lead Time',
    definition:
      'In inventory, lead time refers to how many days it takes to receive your order once placed.',
  },
  {
    term: 'Minimum Order Quantity',
    definition:
      'A minimum order quantity is the smallest amount of product a supplier will sell to a business placing an order.',
  },
  {
    term: 'Moving Average Cost',
    definition:
      'Moving average cost is the cost of existing inventory on hand plus the cost of new inventory ordered divided by the exact number of items in stock.',
  },
  {
    term: 'MRO Inventory',
    definition:
      'MRO inventory stands for maintenance, repair, and operations inventory. While not “sold” to a customer, this inventory is utilized by a business to conduct preventive and corrective maintenance on an asset or keep day-to-day business activities running efficiently.',
  },
  {
    term: 'Pick List',
    definition:
      'A pick list is an essential document that communicates precisely what inventory a warehouse picker will need to “pick” to fulfill a customer’s order.',
  },
  {
    term: 'Purchase Requisition',
    definition:
      'A purchase requisition is an unofficial, internal document your company uses to inform your purchasing department about inventory or assets your company intends to buy.',
  },
  {
    term: 'Safety Stock',
    definition:
      'Safety stock inventory, also referred to as buffer stock, is extra inventory your business keeps on hand in the event of unexpected disruptions or delays.',
  },
  {
    term: 'Supplier Relationship Management',
    definition:
      'Supplier relationship management, also known as SRM, is a big-picture strategy businesses implement to ensure their relationships with suppliers are as optimized, coordinated, and profitable as possible.',
  },
  {
    term: 'UOM Inventory',
    definition:
      'Inventory UoM, or inventory units of measure, are the standardized, physical units by which your business quantifies its stock. Units of measure help businesses understand how much of a given product they really have in stock—and how much they’re ordering from a vendor or selling to a customer.',
  },
  {
    term: 'Vendor Managed Inventory',
    definition:
      'Vendor-managed inventory (VMI) is a common supply chain arrangement where a supplier, manufacturer, or other qualified third-party controls the inventory and inventory-related decisions on behalf of the seller.',
  },
  {
    term: 'Warehouse Management',
    definition:
      'Warehouse management refers to the daily operations that keep your warehouse running smoothly, from ordering more inventory at just the right time to tracking that inventory properly when it’s moved, used, or sold.',
  },
  {
    term: 'Warehouse Management System',
    definition:
      'Warehouse management software, often called a warehouse management system (WMS), is a system that allows your business visibility into your company’s inventory, assets, logistics, and fulfillment operations.',
  },
];
