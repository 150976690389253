'use client';

import { faqs } from '@/data/faqs';
import { glossary } from '@/data/glossary';
import { getAllBlogs } from '@/lib/content/actions';
import { Blog } from '@/types/interfaces';
import { Button, Kbd, rem } from '@mantine/core';
import {
  Spotlight,
  SpotlightActionData,
  SpotlightActionGroupData,
  spotlight,
} from '@mantine/spotlight';
import {
  IconFileText,
  IconHome,
  IconMoneybag,
  IconQuestionMark,
  IconSearch,
  IconSend,
} from '@tabler/icons-react';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

export function AppSpotlight() {
  const router = useRouter();
  const [blogs, setBlogs] = useState<Blog[]>([]);

  useEffect(() => {
    const getData = async () => {
      const data = await getAllBlogs();
      setBlogs(data);
    };
    getData();
  }, []);

  const actions: (SpotlightActionGroupData | SpotlightActionData)[] = [
    {
      group: 'Pages',
      actions: [
        {
          id: 'home',
          label: 'Home',
          description: 'Get to home page',
          onClick: () => router.push('/'),
          leftSection: <IconHome style={{ width: rem(24), height: rem(24) }} stroke={1.5} />,
        },
        {
          id: 'pricing',
          label: 'Pricing',
          description: 'Get full information about pricing',
          onClick: () => router.push('/pricing'),
          leftSection: <IconMoneybag style={{ width: rem(24), height: rem(24) }} stroke={1.5} />,
        },
        {
          id: 'contact',
          label: 'Contact',
          description: 'Get in touch with us',
          onClick: () => router.push('/contact'),
          leftSection: <IconSend style={{ width: rem(24), height: rem(24) }} stroke={1.5} />,
        },
        {
          id: 'FAQ',
          label: 'FAQ',
          description: 'Get answers to frequently asked questions',
          onClick: () => router.push('/FAQs'),
          leftSection: (
            <IconQuestionMark style={{ width: rem(24), height: rem(24) }} stroke={1.5} />
          ),
        },
        {
          id: 'blog',
          label: 'Blog',
          description: 'Visit our blog to get latest news and updates',
          onClick: () => router.push('/blog'),
          leftSection: <IconFileText style={{ width: rem(24), height: rem(24) }} stroke={1.5} />,
        },
        {
          id: 'glossary',
          label: 'Glossary',
          description: 'Learn more about terms used in our documentation',
          onClick: () => router.push('/glossary'),
          leftSection: <IconFileText style={{ width: rem(24), height: rem(24) }} stroke={1.5} />,
        },
        {
          id: 'privacy-policy',
          label: 'Privacy Policy',
          description: 'Learn more about how we handle your data',
          onClick: () => router.push('/privacy-policy'),
          leftSection: <IconFileText style={{ width: rem(24), height: rem(24) }} stroke={1.5} />,
        },
        {
          id: 'terms-of-service',
          label: 'Terms of Service',
          description: 'Learn more about our terms of service',
          onClick: () => router.push('/terms-of-service'),
          leftSection: <IconFileText style={{ width: rem(24), height: rem(24) }} stroke={1.5} />,
        },
        {
          id: 'cookie-policy',
          label: 'Cookie Policy',
          description: 'Learn more about how we use cookies',
          onClick: () => router.push('/cookie-policy'),
          leftSection: <IconFileText style={{ width: rem(24), height: rem(24) }} stroke={1.5} />,
        },
      ],
    },
    {
      group: 'Frequently Asked Questions',
      actions: faqs.map((item) => ({
        id: item.question,
        label: item.question,
        description: item.answer,
        onClick: () => router.push(`/FAQs#${item.question}`),
        leftSection: <IconQuestionMark style={{ width: rem(24), height: rem(24) }} stroke={1.5} />,
      })),
    },
    {
      group: 'Glossary',
      actions: glossary.map((item) => ({
        id: item.term,
        label: item.term,
        description: item.definition,
        onClick: () => router.push(`/glossary#${item.term}`),
        leftSection: <IconFileText style={{ width: rem(24), height: rem(24) }} stroke={1.5} />,
      })),
    },
    {
      group: 'Blogs',
      actions: blogs.map((blog) => ({
        id: blog.slug,
        label: blog.title,
        description: new Date(blog.date).toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
        onClick: () => router.push(`/blog/${blog.slug}`),
        leftSection: <IconFileText style={{ width: rem(24), height: rem(24) }} stroke={1.5} />,
      })),
    },
  ];

  return (
    <>
      {/* <NavLink
        onClick={spotlight.open}
        leftSection={<IconSearch size="1rem" stroke={1.5} />}
        label="Search"
      /> */}
      <Button
        variant="default"
        color="gray"
        visibleFrom="xs"
        onClick={spotlight.open}
        leftSection={<IconSearch size={16} color="var(--mantine-color-dimmed)" />}
        rightSection={
          <Kbd ml={5} size="xs">
            /
          </Kbd>
        }
        styles={{
          label: { fontWeight: '400', color: 'var(--mantine-color-dimmed)', marginRight: '3rem' },
        }}
      >
        Search
      </Button>
      <Spotlight
        actions={actions}
        scrollable
        maxHeight={350}
        shortcut={['mod + P', 'mod + K', '/']}
        nothingFound="Nothing found..."
        highlightQuery
        searchProps={{
          leftSection: <IconSearch style={{ width: rem(20), height: rem(20) }} stroke={1.5} />,
          placeholder: 'Search...',
        }}
      />
    </>
  );
}
